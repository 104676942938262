<template>
  <div class="article" @scroll="handleScroll">
    <error :errMsg="articleMsg" v-if="articleMsg" />
    <div v-else class="content" id="content">
      <div ref="contentDom">
        <div class="title-group">
          <div class="title" v-if="data && data.title">{{ data.title }}</div>
          <div class="date" v-if="data && data.publishTime">{{ data.publishTime }}</div>
        </div>
        <!-- 名片 -->
        <card-new />
        <div v-html="data && data.detail" class="article-content"></div>
        <!-- 聊一聊 -->
        <fixed-button-new />
        <div class="footer" v-if="!$route.from">
          <span v-if="data && typeof data.sharePv === 'number'">
            阅读
            <span class="num m-r-16">{{ data.visitPv }}</span>
          </span>
          <span v-if="data && typeof data.sharePv === 'number'">
            分享
            <span class="num">{{ data.sharePv }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    data() {
      return {
        data: null,
        isPreview: false,
        pageOverLay: false,
        cmeId: '',
        cardId: '',
        extField: '',
        articleMsg: null,
        cardInfo: {},
        eventId: null,
        trackDataTemp: {
          durationTimes: null,
          isFinished: null,
        },
        trackData: {},
      };
    },
    computed: {
      ...mapState('app', ['paramsData']),
    },
    async mounted() {
      try {
        const data = await this.initPage();
        console.log('data', data);
        if (data.title) {
          document.title = data.title;
        }
        if (data.detail) {
          data.detail = JSON.parse(data.detail);
        }
        this.data = data;
        if (!this.$route.query.from) {
          TrackEvent.contentViewTrace((id) => {
            //回调本次事件id；
            this.eventId = id;
          });
          TrackEvent.contentRadarTrace();
        }
      } catch (error) {
        console.error('initPage', error.msg);
        if (error.msg) {
          this.articleMsg = error.msg;
        } else if (error.toString().indexOf('Unexpected token') > -1) {
          this.articleMsg = '文章格式错误';
        }
      }
    },
    methods: {
      ...mapActions('app', ['initPage']),
      // 获取是否滚动到底部
      handleScroll() {
        let innerHeight = window.innerHeight;
        let realHeight = event.target.scrollHeight;
        let diff = realHeight - innerHeight;
        let scrollTop = event.target.scrollTop;
        // 滚动条到底部的条件
        if (scrollTop >= diff && !this.paramsData.params.from) {
          //上报浏览文章完成事件
          if (sessionStorage.getItem('viewEnd') === 'complete') return;
          TrackEvent.viewComplete();
          sessionStorage.setItem('viewEnd', 'complete');
        }
      },
    },
  };
</script>

<style lang="less" scoped>
.article {
  //padding: 26px 20px;
  //width: 335px;
  height: 100vh;
  overflow-y: auto;
  height: 100vh;
  overflow-x: hidden;
  //height: calc(100vh - 36px);
}
.content {
  padding: 26px 20px;
  box-sizing: border-box;
}
.title-group {
  margin-bottom: 16px;
}
.article-content {
  margin-top: 16px;
}
.title {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}
.date {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  font-size: 12px;
}

.footer {
  margin-top: 24px;
  font-size: 12px;
  color: #999999;
  .num {
    color: #4d78ff;
  }
  .m-r-16 {
    margin-right: 16px;
  }
}
</style>
